import { UiButton } from 'shared/ui/UiButton';
import BannerImg from 'assets/images/banner-img.png';
import { Link, NavLink } from 'react-router-dom';
import { useDevice } from 'hooks/useDevice';
export const Banner = ({ bgColor = 'blue', content }) => {
  const { isMobile } = useDevice();
  const ReturnLinkUi = ({ external, internal, children }) => {
    if (external && internal)
      return (
        <a target="_blank" rel="noreferrer" href={external}>
          {children}
        </a>
      );
    else if (external && !internal)
      return (
        <a target="_blank" rel="noreferrer" href={external}>
          {children}
        </a>
      );
    else if (internal && !external) {
      return <Link to={internal}>{children}</Link>;
    }
  };
  const classNames = `banner banner--${bgColor}`;
  return (
    <div className={classNames}>
      <ReturnLinkUi
        external={content?.external_link}
        internal={content?.internal_link}
      >
        <div className="banner__content">
          <div className="banner__content-head">
            <h3 className="banner__title">{content?.title}</h3>
            <UiButton
              className="banner__disabled-btn"
              disabled
              color="primary"
              label={content?.btnLabel}
            />
            <UiButton
              className="banner__mobile-btn"
              disabled
              color="primary"
              label={content?.label_link}
            />
          </div>

          <div className="banner__link">
            {content?.label_link} <span>→</span>
          </div>
        </div>
        <div className="banner__preview">
          <img
            src={
              process.env.REACT_APP_BACKEND_URL +
              (isMobile
                ? content?.image_mobile?.data?.attributes?.url
                : content?.image?.data?.attributes?.url)
            }
            alt="BannerImg"
          />
        </div>
      </ReturnLinkUi>
    </div>
  );
};
