import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { UiButton } from 'shared/ui/UiButton';
import { UiField } from 'shared/ui/UiField';
import { UiTitle } from 'shared/ui/UiTitle';
import { CREATE_COLLABORATION } from 'services/graphql/mutations/collaborate';
import { DialogContentText, TextField } from '@mui/material';
import { SmartCaptcha } from '@yandex/smart-captcha';
import { UiModal } from 'shared/ui/UiModal';

const Collaborate = () => {
  const [createCollaborationRequest] = useMutation(CREATE_COLLABORATION);
  const captchaRef = useRef(null);
  const [token, setToken] = useState('');
  const [status, setStatus] = useState('hidden');
  const [openModal, setOpenModal] = useState(false);
  const [modalStatus, setStatusModal] = useState('success'); // success || error
  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '+7',
      text: '',
      loading: false,
      submit: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Введите Имя'),
      text: Yup.string().required('Введите ваше предложение'),
      phone: Yup.string()
        .matches(/^\+?\d{1,}$/, 'Введите правильный номер телефона')
        .min(
          7,
          'Минимальная длина номера телефона должна быть не менее 7 символов'
        )
        .max(
          15,
          'Максимальная длина номера телефона должна быть не более 15 символов'
        )
        .required('Введите номер телефона'),
    }),
    validateOnChange: false,
    onSubmit: async (values, helpers) => {
      helpers.setFieldValue('loading', true);

      if (status !== 'success') {
        setStatus('network-error');
        return false;
      }
      console.log('handleSubmit');
      try {
        await createCollaborationRequest({
          variables: {
            name: values.name,
            phone: values.phone,
            text: values.text,
          },
        });
        console.log('create request');
        helpers.setFieldValue('loading', false);
        resetFormikState();
        setStatusModal('success');
        setOpenModal(true);
        setToken('');
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
        helpers.setFieldValue('loading', false);
        setOpenModal(true);
        setStatusModal('error');
        setToken('');
      }
    },
  });

  const handleChallengeVisible = useCallback(() => setStatus('visible'), []);
  const handleChallengeHidden = useCallback(() => setStatus('hidden'), []);
  const handleNetworkError = useCallback(() => setStatus('network-error'), []);
  const handleSuccess = useCallback((token) => {
    setStatus('success');
    setToken(token);
  }, []);
  const handleTokenExpired = useCallback(() => {
    setStatus('token-expired');
    setToken('');
    console.log('handleTokenExpired');
    captchaRef.current.reset();
  }, []);

  const resetFormikState = () => {
    formik.resetForm();
    formik.setStatus({});
    formik.setErrors({});
    formik.setSubmitting(false);
  };

  useEffect(() => {
    handleChallengeVisible();
  }, []);

  return (
    <main className="collaborate">
      <div className="container">
        <UiTitle className="collaborate__title" label="Для сотрудничества" />
        <div className="collaborate__content">
          <div className="collaborate__desc">
            <p> 
              Предлагаем в аренду помещения для проведения занятий, семинаров,
              курсов, тренингов, мастер-классов, собраний, заседаний и других
              мероприятий. <br /> Принимаем предложения по размещению Вашей
              рекламы. Подробней по телефону 8-800-500-03-56.
            </p>
          </div>

          <form
            noValidate
            onSubmit={formik.handleSubmit}
            className={`home-booking__form ${
              status === 'network-error' ? 'error' : ''
            }`}
          >
            <h5 className="collaborate__form-title">Связаться</h5>
            <TextField
              error={!!(formik.touched.name && formik.errors.name)}
              fullWidth
              helperText={formik.touched.name && formik.errors.name}
              placeholder="Имя"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.name}
              size="medium"
              className="home-booking__field"
            />
            <TextField
              error={!!(formik.touched.phone && formik.errors.phone)}
              fullWidth
              helperText={formik.touched.phone && formik.errors.phone}
              placeholder="Телефон"
              name="phone"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="tel"
              value={formik.values.phone}
              size="medium"
              className="home-booking__field"
            />
            <TextField
              error={!!(formik.touched.text && formik.errors.text)}
              fullWidth
              helperText={formik.touched.text && formik.errors.text}
              placeholder="Ваше предложение"
              name="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.text}
              size="medium"
              className="home-booking__field"
              multiline
              minRows={5}
            />
            <div className="home-booking__captcha">
              <SmartCaptcha
                ref={captchaRef}
                sitekey="ysc1_zfkWTI2gHXvamgAbm7G4b8unKmlM7F25d0V3jRZFd5cea92d"
                onChallengeVisible={handleChallengeVisible}
                onChallengeHidden={handleChallengeHidden}
                onNetworkError={handleNetworkError}
                onSuccess={handleSuccess}
                onTokenExpired={handleTokenExpired}
              />
              {status === 'network-error' && (
                <div className="captcha-error-text">Пройдите капчу</div>
              )}
            </div>

            <UiButton
              type="submit"
              disabled={formik.values.loading}
              label="Написать нам"
              color="light"
            />
            <div className="collaborate__form-desc">
              Нажимая «Написать нам», Вы соглашаетесь с нашей политикой
              обработки персональных данных
            </div>
          </form>
        </div>
      </div>

      {modalStatus === 'success' && (
        <UiModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          title="Ваша заявка принята"
        >
          <DialogContentText>
            Скоро наш менеджер свяжется с вами
          </DialogContentText>
        </UiModal>
      )}
      {modalStatus === 'error' && (
        <UiModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          title="Что-то пошло не так"
          successed={false}
        >
          <DialogContentText>
            Возможно, сбой соединения. Попробуйте оставить заявку позже или
            просто позвоните нам по номеру: <br />{' '}
            {localStorage.getItem('official_phone') || ''}
          </DialogContentText>
        </UiModal>
      )}
    </main>
  );
};

export default Collaborate;
