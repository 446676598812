import React, { useEffect, useState } from 'react';
import { UiTitle } from 'shared/ui/UiTitle';
import Dialog from '@mui/material/Dialog';
import withScrollToTop from 'hocs/withScrollToTop';
import { useQuery } from '@apollo/client';
import { GET_GALLERY_PHOTOS } from 'services/graphql/gallery';
import Pagination from 'components/Pagination';
import { ReactComponent as SliderArrowRight } from 'assets/icons/gallery-arrow-right.svg';
import { ReactComponent as SliderArrowLeft } from 'assets/icons/gallery-arrow-left.svg';
import { useDevice } from 'hooks/useDevice';
import InfiniteScroll from 'react-infinite-scroll-component';

//  skeletons
import SkeletonSX from 'assets/images/skeletons/card-sx.png';

const Gallery = () => {
  const [items, setItems] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const { isMobile, isTablet } = useDevice();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const { loading, error, data } = useQuery(GET_GALLERY_PHOTOS, {
    variables: { start: page, limit: rowsPerPage },
    onCompleted: (data) => {
      setTotalCount(data.galleryPhotos.meta.pagination.pageCount);
      setItems((prevState) => [...prevState, ...data?.galleryPhotos?.data]);
    },
  });

  // useEffect(() => {
  //   if (isTablet || isMobile) {
  //     document.querySelector('.footer').classList.add('fixed');
  //   }
  // }, [isTablet, isMobile]);

  const handleClose = () => {
    setActiveImageIndex(null);
    setOpenModal(false);
  };

  const handleClickItem = (index) => {
    handleChangeIndex(index);
    setOpenModal(true);
  };
  const handleChangeIndex = (index) => {
    if (index >= 0 && index < data?.galleryPhotos?.data.length)
      setActiveImageIndex(index);
  };

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
    const pageNumber = value - 1;
    if (pageNumber >= 0) {
      setPage(pageNumber * rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  console.log(items);

  const more = () => {
    handleChangePage(null, currentPage + 1);
  };

  const skeletons = [1, 2, 3, 4, 5, 6];
  return (
    <main className="gallery">
      <div className="container">
        <UiTitle className="gallery__title" label="Фотогалерея" />
        {isMobile || isTablet ? (
          <InfiniteScroll
            dataLength={items?.length}
            next={more}
            hasMore={true}
            className="infinity-scroll"
            // loader={<p>Loading...</p>}
            // endMessage={<p>No more data to load.</p>}
          >
            <ul className="gallery__list">
              {items?.map(({ id, attributes }, index) => (
                <li
                  key={id}
                  // onClick={() => handleClickItem(index)}
                  className="gallery__item"
                >
                  <img
                    src={
                      process.env.REACT_APP_BACKEND_URL +
                      attributes?.image?.data?.attributes.url
                    }
                    alt=""
                  />
                </li>
              ))}
            </ul>
          </InfiniteScroll>
        ) : (
          <div className="gallery__content">
            <ul className="gallery__list">
              {loading &&
                skeletons.map((value) => (
                  <li key={value} className="gallery__item">
                    <img
                      src={SkeletonSX}
                      className="skeleton"
                      alt="skeleton-SX"
                    />
                  </li>
                ))}
              {!loading &&
                !error &&
                data?.galleryPhotos?.data?.map(({ id, attributes }, index) => (
                  <li
                    key={id}
                    onClick={() => handleClickItem(index)}
                    className="gallery__item"
                  >
                    <img
                      src={
                        process.env.REACT_APP_BACKEND_URL +
                        attributes?.image?.data?.attributes.url
                      }
                      alt="gallery-img"
                    />
                  </li>
                ))}
            </ul>

            <Pagination
              count={totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              onChange={handleChangePage}
              onChangePageSize={handleChangeRowsPerPage}
              pageSize={rowsPerPage}
            />
          </div>
        )}
      </div>

      <Dialog className="gallery__modal" onClose={handleClose} open={openModal}>
        <div
          className="gallery__modal-arrow left"
          onClick={() => handleChangeIndex(activeImageIndex - 1)}
        >
          <SliderArrowLeft />
        </div>
        <div className="gallery__modal-content">
          <div className="gallery__modal-preview">
            {activeImageIndex !== null && (
              <img
                src={
                  process.env.REACT_APP_BACKEND_URL +
                  data?.galleryPhotos?.data[activeImageIndex].attributes?.image
                    ?.data?.attributes.url
                }
                alt="gallery__modal-img"
              />
            )}
          </div>
        </div>
        <div
          className="gallery__modal-arrow right"
          onClick={() => handleChangeIndex(activeImageIndex + 1)}
        >
          <SliderArrowRight />
        </div>
      </Dialog>
    </main>
  );
};

export default withScrollToTop(Gallery);
