import React from 'react';

export const UiTitle = ({ label, className = '' }) => {
  const classNames = `ui-title ${className}`;
  return (
    <div className={classNames}>
      <h2 className="section__title primary">{label}</h2>
    </div>
  );
};
