import { useQuery } from "@apollo/client";
import { ReactComponent as LogoIcon } from "assets/icons/mik2000.svg";
import { ReactComponent as FlagIcon } from "assets/icons/flag.svg";
import { GET_FOOTER_CONTENT } from "services/graphql/footer";
export const Footer = () => {
  const { data } = useQuery(GET_FOOTER_CONTENT);
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__content">
          <div className="footer__flag">
            <FlagIcon />
          </div>
          <h6 className="footer__title">МИК осн.2000</h6>
          <nav className="footer__nav">
            <ul className="footer__list">
              {data?.footer?.data?.attributes?.documents.map(
                ({ id, title, document }) => (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      process.env.REACT_APP_BACKEND_URL +
                      document?.data?.attributes.url
                    }
                    key={id}
                    className="about__stack-link"
                  >
                    <li className="footer__item">{title}</li>
                  </a>
                )
              )}
            </ul>
          </nav>
          <div className="footer__corp">© 2020 МИК-2000</div>
        </div>
      </div>
    </footer>
  );
};
