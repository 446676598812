const { gql } = require('@apollo/client');

export const GET_FOOTER_CONTENT = gql`
	query GetFooter {
		footer {
			data {
				attributes {
					documents {
						id
						title
						document {
							data {
								attributes {
									url
								}
							}
						}
					}
				}
			}
		}
	}
`
