import { gql } from '@apollo/client';

export const CREATE_TRAINING_REQUEST = gql`
  mutation createTrainingRequest(
    $name: String!
    $phone: String!
    $category: ENUM_TRAININGREQUEST_CATEGORY
  ) {
    createTrainingRequest(
      data: { name: $name, phone: $phone, category: $category }
    ) {
      data {
        id
        attributes {
          name
          phone
          category
        }
      }
    }
  }
`;
