const { gql } = require('@apollo/client');

export const GET_ABOUT_CONTENT = gql`
  query GetAboutPage {
    aboutPage {
      data {
        attributes {
          details {
            founder
            address
            schedule
            phone
            email
          }
          director {
            full_name
            phone
            email
          }
          devisions {
            id
            town
            street
            house
            leader
            phone
          }
          documents {
            id
            title
            document {
              data {
                attributes {
                  url
                }
              }
            }
          }

          staff {
            title
            document {
              data {
                attributes {
                  url
                }
              }
            }
          }

          programs {
            id
            title
            document {
              data {
                attributes {
                  url
                }
              }
            }
          }
          plans {
            id
            title
            document {
              data {
                attributes {
                  url
                }
              }
            }
          }
          schedules {
            id
            title
            document {
              data {
                attributes {
                  url
                }
              }
            }
          }
          recommendations {
            id
            title
            document {
              data {
                attributes {
                  url
                }
              }
            }
          }

          performances {
            id
            title
            document {
              data {
                attributes {
                  url
                }
              }
            }
          }

          periods {
            id
            text
          }

          services {
            id
            title
            document {
              data {
                attributes {
                  url
                }
              }
            }
          }

          enrollment {
            id
            text
          }

          venues {
            id
            text
          }

          environment {
            id
            text
          }
          cooperation {
            id
            text
          }
        }
      }
    }
  }
`;
