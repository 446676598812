import { useQuery } from '@apollo/client';
import { ScheduleTable } from 'components/ScheduleTable';
import withScrollToTop from 'hocs/withScrollToTop';
import React from 'react';
import { GET_RECORDS } from 'services/graphql/records';
import { Locations } from 'shared/Locations';
import { UiTitle } from 'shared/ui/UiTitle';

const Shedule = () => {
  const { loading, error, data } = useQuery(GET_RECORDS);

  return (
    <div className="schedule">
      <div className="container schedule__container">
        <UiTitle className="schedule__title" label="Ближайшие группы" />
        <ScheduleTable data={!loading && !error ? data?.records?.data : []} />
      </div>

      <Locations />
    </div>
  );
};

export default withScrollToTop(Shedule);
