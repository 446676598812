const { gql } = require('@apollo/client');

export const GET_INSTRUCTORS = gql`
  query GetInstructors {
    instructors(pagination: { page: 1, pageSize: 100 }) {
      data {
        id
        attributes {
          full_name
          startInstructorYear
          startTeachYear
          car_name

          avatar {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`
