import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from 'assets/icons/submenu-arrow.svg';
export const Submenu = ({ label, list, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="submenu">
      <div className="submenu__control" onClick={() => setIsOpen(!isOpen)}>
        <span>{label}</span>
        <ArrowIcon className={`submenu__arrow ${isOpen ? 'open' : ''}`} />
      </div>
      <ul className={`submenu__list ${isOpen ? 'open' : ''}`}>
        {list.map((item, index) => (
          <NavLink to={item.to} onClick={onClick} key={index}>
            <li className="submenu__item">{item.label}</li>
          </NavLink>
        ))}
      </ul>
    </div>
  );
};
