import React from 'react';
import { UiLink } from 'shared/ui/UiLink';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import moment from 'moment';
import 'moment/locale/ru';
import { useDevice } from 'hooks/useDevice';
export const HomeReviews = ({ data }) => {
  const { isMobile } = useDevice();
  moment.locale('ru');
  return (
    <section className="home-reviews">
      <div className="container">
        <h3 className="home-reviews__title">
          Научим водить даже тех, кто всегда боялся. Вот примеры наших учеников:
        </h3>

        <div className="home-reviews__content">
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2 }}>
            <Masonry gutter={isMobile ? '8px' : '20px'}>
              {data?.map(({ text, author, date }, index) => (
                <li key={index} className="home-reviews__item">
                  <p className="home-reviews__item-text">{text}</p>

                  <div className="home-reviews__item-info">
                    <p className="home-reviews__author">{author}</p>
                    <p className="home-reviews__date">
                      {moment(date).format('DD MMMM YYYY')}
                    </p>
                  </div>
                </li>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>

        <UiLink
          to="/reviews"
          center
          label="Смотреть все отзывы"
          color="primary"
        />
      </div>
    </section>
  );
};
