import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  auth_user: null,
  authenticated: false,
  loading: false,
};

export const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async (_, { dispatch }) => {
    dispatch({ type: 'RESET_STORE' });
  }
);

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoadingUser: (state, action) => {
      state.loading = action.payload;
    },
    setUserData: (state, action) => {
      const { user } = action.payload;
      state.auth_user = user;
      state.authenticated = true;
    },
    setUserLogout: (state) => {
      state.auth_user = initialState.auth_user;
      state.authenticated = initialState.authenticated;
      state.loading = initialState.loading;
    },
  },
});
export const { setLoadingUser, setUserData, setUserLogout } = UserSlice.actions;
export default UserSlice.reducer;
