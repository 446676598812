import withScrollToTop from 'hocs/withScrollToTop';
import React from 'react';
import { UiTitle } from 'shared/ui/UiTitle';
import { useQuery } from '@apollo/client';
import { GET_INSTRUCTORS } from 'services/graphql/instructors';

const skeletons = [1, 2, 3, 4];
const Instructors = () => {
  const { loading, error, data } = useQuery(GET_INSTRUCTORS);
  return (
    <main className="instructors">
      <div className="container">
        <UiTitle className="instructors__title" label="Инструкторы" />
        <div className="instructors__content">
          <ul className="instructors__list">
            {loading &&
              skeletons.map((value) => (
                <li key={value} className="instructors__items skeleton"></li>
              ))}
            {!loading &&
              !error &&
              data.instructors.data?.map(({ id, attributes }) => (
                <li key={id} className="instructors__items">
                  <div className="instructors__items-preview">
                    <img
                      src={
                        process.env.REACT_APP_BACKEND_URL +
                        attributes?.avatar?.data?.attributes.url
                      }
                      alt={attributes.full_name}
                      className="instructors__items-image"
                    />
                  </div>
                  <div className="instructors__items-content">
                    <h5 className="instructors__items-title">
                      {attributes.full_name}
                    </h5>
                    <div className="instructors__items-info">
                      <div>
                        Работает инструктором с {attributes.startInstructorYear}{' '}
                        года
                      </div>
                      {attributes.startTeachYear !== null && (
                        <div>
                          Преподает ПДД c {attributes.startTeachYear} года
                        </div>
                      )}
                      <div>{attributes.car_name}</div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </main>
  );
};

export default withScrollToTop(Instructors);
