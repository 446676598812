import { useCallback, useEffect, useRef, useState } from 'react';
import GlyphIcon from 'assets/icons/glyph-1.svg';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { UiButton } from './ui/UiButton';
import { useMutation } from '@apollo/client';
import { DialogContentText, TextField } from '@mui/material';
import { SmartCaptcha } from '@yandex/smart-captcha';
import { UiModal } from './ui/UiModal';
import { useDevice } from 'hooks/useDevice';
// skeletons
import SkeletonMain from 'assets/images/skeletons/category-page/main.png';
import SkeletonMainMobile from 'assets/images/skeletons/category-page/main-mobile.png';
import SkeletonMD from 'assets/images/skeletons/category-page/md.png';
import SkeletonSX from 'assets/images/skeletons/category-page/sx.png';
import SkeletonSXMobile from 'assets/images/skeletons/category-page/sx-mobile.png';
import { CREATE_TRAINING_REQUEST } from 'services/graphql/mutations/training-requests';
export const CategoryHero = ({
  image,
  image_mobile,
  blockColor,
  formTitle,
  category,
  isLoading,
}) => {
  const { isMobile } = useDevice();
  const captchaRef = useRef(null);
  const [createTrainingRequest] = useMutation(CREATE_TRAINING_REQUEST);
  const [token, setToken] = useState('');
  const [status, setStatus] = useState('hidden');
  const [openModal, setOpenModal] = useState(false);
  const [modalStatus, setStatusModal] = useState('success'); // success || error

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '+7',
      loading: false,
      submit: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Введите Имя'),
      phone: Yup.string()
        .matches(/^\+?\d{1,}$/, 'Введите правильный номер телефона')
        .min(
          7,
          'Минимальная длина номера телефона должна быть не менее 7 символов'
        )
        .max(
          15,
          'Максимальная длина номера телефона должна быть не более 15 символов'
        )
        .required('Введите номер телефона'),
    }),
    validateOnChange: false,
    onSubmit: async (values, helpers) => {
      helpers.setFieldValue('loading', true);

      if (status !== 'success') {
        setStatus('network-error');
        return false;
      }

      try {
        const { data } = await createTrainingRequest({
          variables: {
            name: values.name,
            phone: values.phone,
            category,
          },
        });
        helpers.setFieldValue('loading', false);
        resetFormikState();
        setStatusModal('success');
        setOpenModal(true);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
        helpers.setFieldValue('loading', false);
        setOpenModal(true);
        setStatusModal('error');
      }
    },
  });
  const handleChallengeVisible = useCallback(() => setStatus('visible'), []);
  const handleChallengeHidden = useCallback(() => setStatus('hidden'), []);
  const handleNetworkError = useCallback(() => setStatus('network-error'), []);
  const handleSuccess = useCallback((token) => {
    setStatus('success');
    setToken(token);
  }, []);
  const handleTokenExpired = useCallback(() => {
    setStatus('token-expired');
    setToken('');
    captchaRef.current.reset();
  }, []);

  const resetFormikState = () => {
    formik.resetForm();
    formik.setStatus({});
    formik.setErrors({});
    formik.setSubmitting(false);
  };

  useEffect(() => {
    handleChallengeVisible();
  }, [handleNetworkError]);
  console.log('isLoading', isLoading);

  const showSkeletons =
    (image?.data?.attributes.url ||
      image_mobile?.data?.attributes.url !== null) !== null && isLoading;

  return (
    <div className="category-hero">
      <div className="container category-hero__container">
        <div className="category-hero__preview">
          <img
            src={
              showSkeletons
                ? !isMobile
                  ? SkeletonMain
                  : SkeletonMainMobile
                : image_mobile?.data?.attributes?.url ||
                  image?.data?.attributes?.url
                ? process.env.REACT_APP_BACKEND_URL +
                  (isMobile
                    ? image_mobile?.data?.attributes?.url
                    : image?.data?.attributes?.url)
                : !isMobile
                ? SkeletonMain
                : SkeletonMainMobile
            }
            alt=""
          />

          {showSkeletons ? (
            <img
              src={isMobile ? SkeletonSXMobile : SkeletonSX}
              className="skeleton skeleton-sx"
              alt="skeleton-sx"
            />
          ) : (
            <div className={`category-hero__block ${blockColor}`}>
              <img src={GlyphIcon} className="category-hero__icon" alt="" />
              <p className="category-hero__label">Скидки учащимся и военным</p>
            </div>
          )}
        </div>

        {!isMobile && showSkeletons ? (
          <img
            src={SkeletonMD}
            className="skeleton skeleton-md"
            alt="skeleton-md"
          />
        ) : (
          <form
            onSubmit={formik.handleSubmit}
            className={`category-hero__form ${
              status === 'network-error' ? 'error' : ''
            }`}
          >
            <h6 className="category-hero__form-title">{formTitle}</h6>

            <TextField
              error={!!(formik.touched.name && formik.errors.name)}
              fullWidth
              helperText={formik.touched.name && formik.errors.name}
              placeholder="Имя"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.name}
              size="medium"
              className="home-booking__field"
            />
            <TextField
              error={!!(formik.touched.phone && formik.errors.phone)}
              fullWidth
              helperText={formik.touched.phone && formik.errors.phone}
              placeholder="Телефон"
              name="phone"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="tel"
              value={formik.values.phone}
              size="medium"
              className="home-booking__field"
            />
            <div className="home-booking__captcha">
              <SmartCaptcha
                ref={captchaRef}
                sitekey="ysc1_zfkWTI2gHXvamgAbm7G4b8unKmlM7F25d0V3jRZFd5cea92d"
                onChallengeVisible={handleChallengeVisible}
                onChallengeHidden={handleChallengeHidden}
                onNetworkError={handleNetworkError}
                onSuccess={handleSuccess}
                onTokenExpired={handleTokenExpired}
              />
              {status === 'network-error' && (
                <div className="captcha-error-text">Пройдите капчу</div>
              )}
            </div>

            <UiButton
              className="category-hero__form-btn"
              label="Записаться"
              color="light"
              type="submit"
              disabled={formik.values.loading}
            />

            <div className="category-hero__form-desc">
              <p>
                Нажимая «Записаться», Вы соглашаетесь с нашей политикой
                обработки персональных данных
              </p>
            </div>
          </form>
        )}
      </div>

      {modalStatus === 'success' && (
        <UiModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          title="Ваша заявка принята"
        >
          <DialogContentText>
            Скоро наш менеджер свяжется с вами
          </DialogContentText>
        </UiModal>
      )}
      {modalStatus === 'error' && (
        <UiModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          title="Что-то пошло не так"
          successed={false}
        >
          <DialogContentText>
            Возможно, сбой соединения. Попробуйте оставить заявку позже или
            просто позвоните нам по номеру: <br />{' '}
            {localStorage.getItem('official_phone') || ''}
          </DialogContentText>
        </UiModal>
      )}
    </div>
  );
};
