import { useQuery } from '@apollo/client';
import withScrollToTop from 'hocs/withScrollToTop';
import React from 'react';
import { GET_ABOUT_CONTENT } from 'services/graphql/pages/about';
import { UiTitle } from 'shared/ui/UiTitle';

const About = () => {
  const { loading, error, data } = useQuery(GET_ABOUT_CONTENT);
  return (
    <main className="about">
      <div className="container">
        <UiTitle className="about__title" label="Cведения об организации" />
        <div className="about__content">
          <div className="about__stack">
            <h5 className="about__stack-title">Основные сведения</h5>
            <ul className="about__stack-list">
              <li className="about__stack-item">
                ООО "МИК-2000", образовано 23 марта 2000 года.
              </li>

              {!loading && !error ? (
                <>
                  <li className="about__stack-item">
                    Учредитель:{' '}
                    {data?.aboutPage?.data?.attributes?.details?.founder}
                  </li>
                  <li className="about__stack-item">
                    Юридический адрес:{' '}
                    {data?.aboutPage?.data?.attributes?.details?.address}
                  </li>
                  <li className="about__stack-item">
                    График работы:{' '}
                    {data?.aboutPage?.data?.attributes?.details?.schedule}
                  </li>
                  <li className="about__stack-item">
                    {data?.aboutPage?.data?.attributes?.details?.phone}{' '}
                    {data?.aboutPage?.data?.attributes?.details?.email}
                  </li>
                </>
              ) : null}
            </ul>
          </div>

          <div className="about__stack">
            <h5 className="about__stack-title">
              Структура и органы управления
            </h5>
            <ul className="about__stack-list">
              {!loading && !error ? (
                <>
                  <li className="about__stack-item">
                    Директор автошколы:{' '}
                    {data?.aboutPage?.data?.attributes?.director?.full_name}
                  </li>
                  <li className="about__stack-item">
                    {data?.aboutPage?.data?.attributes?.director?.phone}{' '}
                    {data?.aboutPage?.data?.attributes?.director?.email}
                  </li>
                </>
              ) : null}
            </ul>
          </div>

          <div className="about__stack">
            <h5 className="about__stack-title">Структурные подразделения:</h5>
            <ul className="about__stack-list">
              {!loading && !error ? (
                <>
                  {data?.aboutPage?.data?.attributes?.devisions?.map(
                    ({ id, town, street, house, leader, phone }) => (
                      <li key={id} className="about__stack-item">
                        {town}
                        {', '}
                        {street} {house}
                        {'.'} Руководитель {leader} {phone}
                      </li>
                    )
                  )}
                </>
              ) : null}
            </ul>
          </div>

          <div className="about__stack">
            <h5 className="about__stack-title">Документы</h5>
            <ul className="about__stack-list">
              {!loading && !error ? (
                <>
                  {data?.aboutPage?.data?.attributes?.documents?.map(
                    ({ id, title, document }) => (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          process.env.REACT_APP_BACKEND_URL +
                          document?.data?.attributes.url
                        }
                        key={id}
                        className="about__stack-link"
                      >
                        <li className="about__stack-item pointer">{title}</li>
                      </a>
                    )
                  )}
                </>
              ) : null}
            </ul>
          </div>

          <div className="about__stack">
            {!loading && !error ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  process.env.REACT_APP_BACKEND_URL +
                  data?.aboutPage?.data?.attributes?.staff?.document?.data?.attributes.url
                }
                className="about__stack-subtitle about__stack-subtitle--link"
              >
                {data?.aboutPage?.data?.attributes?.staff?.title}
              </a>
            ) : null}
          </div>

          <div className="about__stack">
            <h5 className="about__stack-title">Образовательные программы</h5>
            <ul className="about__stack-list">
              {!loading && !error ? (
                <>
                  {data?.aboutPage?.data?.attributes?.programs?.map(
                    ({ id, title, document }) => (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          process.env.REACT_APP_BACKEND_URL +
                          document?.data?.attributes.url
                        }
                        key={id}
                        className="about__stack-link"
                      >
                        <li className="about__stack-item pointer">{title}</li>
                      </a>
                    )
                  )}
                </>
              ) : null}
            </ul>
          </div>

          <div className="about__stack">
            <h5 className="about__stack-title">Учебные планы:</h5>
            <ul className="about__stack-list">
              {!loading && !error ? (
                <>
                  {data?.aboutPage?.data?.attributes?.plans?.map(
                    ({ id, title, document }) => (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          process.env.REACT_APP_BACKEND_URL +
                          document?.data?.attributes.url
                        }
                        key={id}
                        className="about__stack-link"
                      >
                        <li className="about__stack-item pointer">{title}</li>
                      </a>
                    )
                  )}
                </>
              ) : null}
            </ul>
          </div>

          <div className="about__stack">
            <h5 className="about__stack-title">Календарные учебные графики:</h5>
            <ul className="about__stack-list">
              {!loading && !error ? (
                <>
                  {data?.aboutPage?.data?.attributes?.schedules?.map(
                    ({ id, title, document }) => (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          process.env.REACT_APP_BACKEND_URL +
                          document?.data?.attributes.url
                        }
                        key={id}
                        className="about__stack-link"
                      >
                        <li className="about__stack-item pointer">{title}</li>
                      </a>
                    )
                  )}
                </>
              ) : null}
            </ul>
          </div>

          <div className="about__stack">
            <h5 className="about__stack-title">Методические рекомендации:</h5>
            <ul className="about__stack-list">
              {!loading && !error ? (
                <>
                  {data?.aboutPage?.data?.attributes?.recommendations?.map(
                    ({ id, title, document }) => (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          process.env.REACT_APP_BACKEND_URL +
                          document?.data?.attributes.url
                        }
                        key={id}
                        className="about__stack-link"
                      >
                        <li className="about__stack-item pointer">{title}</li>
                      </a>
                    )
                  )}
                </>
              ) : null}
            </ul>
          </div>

          <div className="about__stack">
            <h5 className="about__stack-title">
              Финансово-хозяйственная деятельность
            </h5>
            <ul className="about__stack-list">
              {!loading && !error ? (
                <>
                  {data?.aboutPage?.data?.attributes?.performances?.map(
                    ({ id, title, document }) => (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          process.env.REACT_APP_BACKEND_URL +
                          document?.data?.attributes.url
                        }
                        key={id}
                        className="about__stack-link"
                      >
                        {title}
                      </a>
                    )
                  )}
                </>
              ) : null}
            </ul>
          </div>

          <div className="about__stack">
            <h5 className="about__stack-title">Сроки обучения на категории:</h5>
            <ul className="about__stack-list">
              {!loading && !error ? (
                <>
                  {data?.aboutPage?.data?.attributes?.periods?.map(
                    ({ id, text }) => (
                      <li key={id} className="about__stack-item pointer">
                        {text}
                      </li>
                    )
                  )}
                </>
              ) : null}
            </ul>
          </div>

          <div className="about__stack">
            <h5 className="about__stack-title">
              Платные образовательные услуги
            </h5>
            <ul className="about__stack-list">
              {!loading && !error ? (
                <>
                  {data?.aboutPage?.data?.attributes?.services?.map(
                    ({ id, title, document }) => (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          process.env.REACT_APP_BACKEND_URL +
                          document?.data?.attributes.url
                        }
                        key={id}
                        className="about__stack-link"
                      >
                        <li className="about__stack-item pointer">{title}</li>
                      </a>
                    )
                  )}
                </>
              ) : null}
            </ul>
          </div>

          <div className="about__stack">
            <h5 className="about__stack-title">
              Информация о численности обучающихся:
            </h5>
            <ul className="about__stack-list">
              {!loading && !error ? (
                <>
                  {data?.aboutPage?.data?.attributes?.enrollment?.map(
                    ({ id, text }, index) => (
                      <li key={id} className="about__stack-item numbers">
                        <span>{index + 1}.</span>
                        {text}
                      </li>
                    )
                  )}
                </>
              ) : null}
            </ul>
          </div>

          <div className="about__stack">
            <h5 className="about__stack-title">
              Материально-техническое обеспечение учебного процесса
              Оборудованные объекты для проведения занятий:
            </h5>
            <ul className="about__stack-list">
              {!loading && !error ? (
                <>
                  {data?.aboutPage?.data?.attributes?.venues?.map(
                    ({ id, text }) => (
                      <li key={id} className="about__stack-item pointer">
                        {text}
                      </li>
                    )
                  )}
                </>
              ) : null}
            </ul>
          </div>

          <div className="about__stack about__stack--big">
            <h5 className="about__stack-subtitle">Доступная среда</h5>
            <ul className="about__stack-list">
              {!loading && !error ? (
                <>
                  {data?.aboutPage?.data?.attributes?.environment?.map(
                    ({ id, text }) => (
                      <li key={id} className="about__stack-item pointer">
                        {text}
                      </li>
                    )
                  )}
                </>
              ) : null}
            </ul>
          </div>

          <div className="about__stack">
            <h5 className="about__stack-title">Международное сотрудничество</h5>
            <ul className="about__stack-list">
              {!loading && !error ? (
                <>
                  {data?.aboutPage?.data?.attributes?.cooperation?.map(
                    ({ id, text }) => (
                      <li key={id} className="about__stack-item">
                        {text}
                      </li>
                    )
                  )}
                </>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
};

export default withScrollToTop(About);
