import { combineReducers, configureStore } from '@reduxjs/toolkit';

import UserSlice from './features/UserSlice';

const combinedReducer = combineReducers({
  user: UserSlice,
});

const store = configureStore({
  reducer: (rootSate, action) => {
    const state = rootSate;
    if (action.type === 'RESET_STORE') {
      return combinedReducer(undefined, action);
    }
    return combinedReducer(state, action);
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
