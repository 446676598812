import withScrollToTop from 'hocs/withScrollToTop';
import React from 'react';
import { Locations } from 'shared/Locations';

const Contacts = () => {
  return (
    <main className="contacts">
      <Locations title="Контакты" />
    </main>
  );
};

export default withScrollToTop(Contacts);