import React, { useEffect } from 'react';
import { YMaps, Map as MapComponent, Placemark } from 'react-yandex-maps';

export const Map = ({ points }) => {
  return (
    <div className="map">
      <YMaps query={{ apikey: 'b48b31a8-091e-4e45-b490-75941c36dc0c' }}>
        <MapComponent
          className="yandex-map"
          defaultState={{ center: [55.378186, 36.725078], zoom: 8 }}
        >
          {points?.map((mark, index) => (
            <Placemark
              key={index}
              geometry={[mark.x, mark.y]}
              options={{ iconColor: 'red' }}
              modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
            />
          ))}
        </MapComponent>
      </YMaps>
    </div>
  );
};
