const { gql } = require('@apollo/client');

export const GET_CARS = gql`
	query GetCars {
		cars(pagination: { page: 1, pageSize: 100 }) {
			data {
				id
				attributes {
					brand
					model
					car_detail {
						id
						year
						acp
					}
					image {
						data {
							id
							attributes {
								url
							}
						}
					}
				}
			}
		}
	}
`
