import React from 'react';
import { UiTitle } from './ui/UiTitle';
import { BannerBadge } from 'components/BannerBadge';

export const CategoryPageMobile = ({
  content,
  returnStatusTraining,
  returnTransmission,
  returnCategory,
}) => {
  return (
    <section className="category-page-mobile">
      <UiTitle
        className="category-page-mobile__title"
        label="Стоимость и процесс обучения"
      />

      <div className="container">
        <div className="category-page-mobile__info">
          {content?.training_info?.map((item) => (
            <div
              key={item?.id}
              className="category-page-mobile__info-item category-page-mobile__ceil"
            >
              <p>
                {returnCategory(item?.category)}{' '}
                {item?.transmission
                  ? '(' + returnTransmission(item?.transmission) + ')'
                  : ''}
              </p>
              <p>{returnStatusTraining(item?.online, item?.offline)}</p>
              <p>{item?.price.toLocaleString('ru-RU')} рублей</p>
              {item?.price_banner && <BannerBadge label={item.price_banner} />}
            </div>
          ))}
        </div>
        <div className="category-page-mobile__ceil">
          <p className="category-page-mobile__description">
            {content?.description}
          </p>
        </div>

        <div className="category-page-mobile__block">
          <h5 className="category-page-mobile__head-title">
            Как проходит обучение
          </h5>
          <h6 className="category-page-mobile__block-title">
            {content?.theoretical_exercises?.title}
          </h6>
          {content?.theoretical_exercises?.subtitle && (
            <p className="category-page-mobile__block-subtitle">
              {content?.theoretical_exercises?.subtitle}
            </p>
          )}
          <ul className="category-page__list category-page-mobile__list">
            {content?.theoretical_exercises?.list?.map((item, index) => (
              <li
                key={item.id}
                className="category-page__item category-page-mobile__item"
              >
                {item.text}
              </li>
            ))}
          </ul>
        </div>

        <div className="category-page-mobile__ceil category-page-mobile__time">
          <h4 className="category-page-mobile__time-title">
            {content?.theory_time?.title}
          </h4>
          <p className="category-page-mobile__time-text">
            {content?.theory_time?.description}
          </p>
        </div>

        <div className="category-page-mobile__block">
          <h6 className="category-page-mobile__block-title">
            {content?.practical_exercises?.title}
          </h6>
          {content?.practical_exercises?.subtitle && (
            <p className="category-page-mobile__block-subtitle">
              {content?.practical_exercises?.subtitle}
            </p>
          )}
          <ul className="category-page__list category-page-mobile__list">
            {content?.practical_exercises?.list?.map((item, index) => (
              <li
                key={item.id}
                className="category-page__item category-page-mobile__item"
              >
                {item.text}
              </li>
            ))}
          </ul>
        </div>

        <div className="category-page-mobile__ceil category-page-mobile__time">
          <h4 className="category-page-mobile__time-title">
            {content?.practical_time?.title}
          </h4>
          <p className="category-page-mobile__time-text">
            {content?.practical_time?.description}
          </p>
        </div>

        <div className="category-page-mobile__block">
          <h6 className="category-page-mobile__block-title">
            {content?.organization_exam?.title}
          </h6>
          {content?.organization_exam?.subtitle && (
            <p className="category-page-mobile__block-subtitle">
              {content?.organization_exam?.subtitle}
            </p>
          )}
          <ul className="category-page__list category-page-mobile__list">
            {content?.organization_exam?.list?.map((item, index) => (
              <li
                key={item.id}
                className="category-page__item category-page-mobile__item"
              >
                {item.text}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
