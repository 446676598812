import { useQuery } from '@apollo/client';
import { Map } from 'components/Map';
import { GET_LOCATIONS } from 'services/graphql/locations';
import { UiTitle } from 'shared/ui/UiTitle';

export const Locations = ({ title }) => {
  const { loading, error, data } = useQuery(GET_LOCATIONS);
  return (
    <section className="locations">
      <div className="container">
        <UiTitle label={title || 'Наши филиалы'} />
        <div className="locations__content">
          {!loading && !error && (
            <ul className="locations__list">
              {data?.affiliates?.data?.attributes?.affiliates.map(
                ({ id, title, affilate_address, subtitle }) => (
                  <li key={id} className="locations__item">
                    <div className="locations__item-title">{title}</div>

                    <div className="locations__item-info">
                      {subtitle !== null && (
                        <p className="locations__item-schedule">{subtitle}</p>
                      )}

                      {affilate_address?.map(({ text }, index) => (
                        <p key={index}>{text}</p>
                      ))}
                    </div>
                  </li>
                )
              )}
            </ul>
          )}

          <div className="locations__map">
            <Map
              points={
                !loading && !error
                  ? data?.affiliates?.data?.attributes?.map_points
                  : []
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
};
