const { gql } = require('@apollo/client');

export const GET_LOCATIONS = gql`
	query GetAffiliates {
		affiliates {
			data {
				id
				attributes {
					affiliates {
						id
						title
						affilate_address {
							id
							text
						}
						subtitle
					}

					map_points {
						x
						y
						title
					}
				}
			}
		}
	}
`
