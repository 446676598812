import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const withScrollToTop = (WrappedComponent) => {
  const ScrollToTop = (props) => {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return <WrappedComponent {...props} />;
  };

  return ScrollToTop;
};

export default withScrollToTop;
