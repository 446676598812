import React from "react";
import { UiTitle } from "shared/ui/UiTitle";

// temp code
import { UiLink } from "shared/ui/UiLink";
import { Banner } from "components/Banner";
//
export const HomeCars = ({ data, banner }) => {
  return (
    <section className="home-cars">
      <div className="container">
        <UiTitle label="Наши автомобили" />

        <div className="home-cars__content">
          <ul className="home-cars__list">
            {data?.map(({ id, brand, model, year, image }, index) => (
              <li key={id} className="home-cars__item">
                <img
                  src={
                    process.env.REACT_APP_BACKEND_URL +
                    image?.data?.attributes.url
                  }
                  alt={`car-${brand}-${model}`}
                />
                <div className="home-cars__item-name">
                  <span>
                    {brand} {model} {year}
                  </span>
                </div>
              </li>
            ))}
          </ul>
          <UiLink
            center
            to="/about-cars"
            color="dark"
            label="Посмотреть остальные автомобили"
          />
        </div>

        {banner !== null && <Banner content={banner} bgColor="green" />}
      </div>
    </section>
  );
};
