import { useMutation } from '@apollo/client';
import { DialogContentText, TextField } from '@mui/material';
import { SmartCaptcha } from '@yandex/smart-captcha';
import { useFormik } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { returnCategoryValue } from 'services/methods';
import { UiButton } from 'shared/ui/UiButton';
import { UiModal } from 'shared/ui/UiModal';
import * as Yup from 'yup';
import { CREATE_TRAINING_REQUEST } from 'services/graphql/mutations/training-requests';
import moment from 'moment';
import 'moment/locale/ru';
import { ScheduleMobile } from './ScheduleMobile';
import { useDevice } from 'hooks/useDevice';

export const ScheduleTable = ({ data }) => {
  const { isDesktop } = useDevice();
  moment.locale('ru');
  const captchaRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [token, setToken] = useState('');
  const [status, setStatus] = useState('hidden');
  const [modalStatus, setStatusModal] = useState('success'); // success || error
  const [openModalBooking, setOpenModalBooking] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [createTrainingRequest] = useMutation(CREATE_TRAINING_REQUEST);

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '+7',
      loading: false,
      submit: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Введите Имя'),
      phone: Yup.string()
        .matches(/^\+?\d{1,}$/, 'Введите правильный номер телефона')
        .min(
          7,
          'Минимальная длина номера телефона должна быть не менее 7 символов'
        )
        .max(
          15,
          'Максимальная длина номера телефона должна быть не более 15 символов'
        )
        .required('Введите номер телефона'),
    }),
    validateOnChange: false,
    onSubmit: async (values, helpers) => {
      helpers.setFieldValue('loading', true);

      if (status !== 'success') {
        setStatus('network-error');
        return false;
      }
      try {
        await createTrainingRequest({
          variables: {
            name: values.name,
            phone: values.phone,
            category: modalData?.category,
          },
        });

        helpers.setFieldValue('name', '');
        helpers.setFieldValue('phone', '+7');
        helpers.setFieldValue('loading', false);
        handleSuccess();
        resetFormikState();
        setStatusModal('success');
        setOpenModalBooking(false);
        setOpenModal(true);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
        helpers.setFieldValue('loading', false);
        setOpenModalBooking(false);
        setOpenModal(true);
        setStatusModal('error');
      }
    },
  });
  const handleChallengeVisible = useCallback(() => setStatus('visible'), []);
  const handleChallengeHidden = useCallback(() => setStatus('hidden'), []);
  const handleNetworkError = useCallback(() => setStatus('network-error'), []);
  const handleSuccess = useCallback((token) => {
    setStatus('success');
    setToken(token);
  }, []);
  const handleTokenExpired = useCallback(() => {
    setStatus('token-expired');
    setToken('');
    captchaRef.current.reset();
  }, []);

  const resetFormikState = () => {
    formik.resetForm();
    formik.setStatus({});
    formik.setErrors({});
    formik.setSubmitting(false);
  };

  useEffect(() => {
    handleChallengeVisible();
  }, []);

  if (!data?.length) {
    return null;
  }
  const returnDayType = (type) => {
    if (type === 'Vyhodnoj') {
      return 'Выходной';
    } else if (type === 'Budni') {
      return 'Будни';
    }
  };
  const returnCategory = (category) => {
    switch (category) {
      case 'A':
        return 'Категория A';
        break;
      case 'B':
        return 'Категория B';
        break;
      case 'B_C':
        return 'B на C';
        break;
      case 'C_D':
        return 'C на D';
        break;

      default:
        break;
    }
  };

  return (
    <div className="schedule-table">
      {isDesktop ? (
        <div className="schedule-table__tbody">
          {data?.map(
            (
              { id, attributes: { category, date, day_type, address } },
              index
            ) => (
              <ul className="schedule-table__tr" key={id}>
                <li className="schedule-table__td">
                  {returnCategory(category)}
                </li>
                <li className="schedule-table__td">
                  {moment(date).format('DD MMMM YYYY')}
                </li>
                <li className="schedule-table__td">
                  {returnDayType(day_type)}
                </li>
                <li className="schedule-table__td">{address}</li>
                <li className="schedule-table__action">
                  <UiButton
                    onClick={() => {
                      setOpenModalBooking(true);
                      setModalData({ category, day_type, date, address });
                    }}
                    label="Записаться"
                    color="green"
                  />
                </li>
              </ul>
            )
          )}

          {!data && (
            <ul className="schedule-table__tr">
              <li className="schedule-table__td">Таблица пуста!</li>
            </ul>
          )}
        </div>
      ) : (
        <ScheduleMobile
          data={data || []}
          returnDayType={returnDayType}
          returnCategory={returnCategory}
          setOpenModalBooking={setOpenModalBooking}
          setModalData={setModalData}
        />
      )}

      <UiModal
        open={openModalBooking}
        onClose={() => setOpenModalBooking(false)}
        title={`Запись на обучение. ${returnCategoryValue(
          modalData?.category
        )}`}
        hideActions
        hideBeforeIcons
      >
        <DialogContentText>
          Оставьте свой номер, наш менеджер перезвонит вам и расскажет по
          дальнейшим шагам
        </DialogContentText>
        <form
          onSubmit={formik.handleSubmit}
          className={`schedule-table__form ${
            status === 'network-error' ? 'error' : ''
          }`}
        >
          <TextField
            error={!!(formik.touched.name && formik.errors.name)}
            fullWidth
            helperText={formik.touched.name && formik.errors.name}
            placeholder="Имя"
            name="name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="text"
            value={formik.values.name}
            size="medium"
            className="schedule-table__field"
          />
          <TextField
            error={!!(formik.touched.phone && formik.errors.phone)}
            fullWidth
            helperText={formik.touched.phone && formik.errors.phone}
            placeholder="Телефон"
            name="phone"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="tel"
            value={formik.values.phone}
            size="medium"
            className="schedule-table__field"
          />
          <div className="schedule-table__captcha">
            <SmartCaptcha
              ref={captchaRef}
              sitekey="ysc1_zfkWTI2gHXvamgAbm7G4b8unKmlM7F25d0V3jRZFd5cea92d"
              onChallengeVisible={handleChallengeVisible}
              onChallengeHidden={handleChallengeHidden}
              onNetworkError={handleNetworkError}
              onSuccess={handleSuccess}
              onTokenExpired={handleTokenExpired}
            />
            {status === 'network-error' && (
              <div className="captcha-error-text">Пройдите капчу</div>
            )}
          </div>
          <UiButton
            type="submit"
            onClose={() => setOpenModalBooking(false)}
            label="Отправить"
            color="green"
            disabled={formik.values.loading}
          />
        </form>
      </UiModal>
      {!openModalBooking && modalStatus === 'success' && (
        <UiModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          title="Ваша заявка принята"
        >
          <DialogContentText>
            Скоро наш менеджер свяжется с вами
          </DialogContentText>
        </UiModal>
      )}
      {!openModalBooking && modalStatus === 'error' && (
        <UiModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          title="Что-то пошло не так"
          successed={false}
        >
          <DialogContentText>
            Возможно, сбой соединения. Попробуйте оставить заявку позже или
            просто позвоните нам по номеру: <br />{' '}
            {localStorage.getItem('official_phone') || ''}
          </DialogContentText>
        </UiModal>
      )}
    </div>
  );
};
