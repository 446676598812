import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import { ReactComponent as VkIcon } from 'assets/icons/vk.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { UiButton } from 'shared/ui/UiButton';
import { MenuPopupState } from './MenuPopupState';
import { useQuery } from '@apollo/client';
import { GET_HEADER_CONTENT } from 'services/graphql/header';
import { useDevice } from 'hooks/useDevice';
import { Submenu } from './Submenu';
import { IconButton } from '@mui/material';
export const Header = () => {
  const { data } = useQuery(GET_HEADER_CONTENT);
  const { isDesktop } = useDevice();
  useEffect(() => {
    if (data?.header?.data?.attributes.phone_1) {
      localStorage.setItem(
        'official_phone',
        formatPhoneNumber(data?.header?.data?.attributes.phone_1)
      );
    }
  }, [data]);
  const initNavState = [
    { label: 'Категория А', to: '/categoryA', popup: null },
    { label: 'Категория B', to: '/categoryB', popup: null },
    {
      activeLabel: 'Переобучение c C на D',
      openPopup: false,
      popup: [
        { label: 'Переобучение c B на C', to: '/categoryBC' },
        { label: 'Переобучение c C на D', to: '/categoryCD' },
      ],
    },
    { label: 'Расписание', to: '/schedule', popup: null },
    { label: 'Отзывы', to: '/reviews', popup: null },
    {
      fixedLabel: 'О школе',
      openPopup: false,
      popup: [
        { label: 'Сведения об организации', to: '/about-organization' },
        { label: 'Наши автомобили', to: '/about-cars' },
        { label: 'Инструкторы', to: '/instructors' },
        { label: 'Фотогалерея', to: '/photo-gallery' },
        { label: 'Контакты', to: '/contacts' },
        { label: 'Сотрудничество', to: '/collaboration' },
      ],
    },
  ];

  const [navState, setNavState] = useState(initNavState);

  const handleClickItem = (index, popupItem) => {
    setNavState((prevState) => {
      const updatedNavState = [...prevState];
      const updatedItem = { ...updatedNavState[index] };
      updatedItem.activeLabel = popupItem.label;
      updatedItem.openPopup = false;
      updatedNavState[index] = updatedItem;
      return updatedNavState;
    });
  };
  const handleCloseMenu = () => {
    setBurgerClass('burger-bar unclicked');
    setMenuClass('mobile-menu hidden');
    document.body.style.overflow = 'auto';
    document.getElementById('root').style.overflow = 'auto';
  };
  function formatPhoneNumber(phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return (
        '+' +
        match[1] +
        ' (' +
        match[2] +
        ') ' +
        match[3] +
        ' ' +
        match[4] +
        ' ' +
        match[5]
      );
    }
    return phoneNumber;
  }

  // to change burger classes
  const [burger_class, setBurgerClass] = useState('burger-bar unclicked');
  const [menu_class, setMenuClass] = useState('mobile-menu hidden');
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  // toggle burger menu change
  const updateMenu = () => {
    if (!isMenuClicked) {
      setBurgerClass('burger-bar clicked');
      setMenuClass('mobile-menu visible');
      document.body.style.overflow = 'hidden';
      document.getElementById('root').style.overflow = 'hidden';
    } else {
      setBurgerClass('burger-bar unclicked');
      setMenuClass('mobile-menu hidden');
      document.body.style.overflow = 'auto';
      document.getElementById('root').style.overflow = 'auto';
    }
    setIsMenuClicked(!isMenuClicked);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="header__content">
          <div className="header__top">
            <div className="header__logo">
              <NavLink to="/">
                <LogoIcon />
              </NavLink>
            </div>
            {isDesktop && (
              <>
                <div className="header__contacts">
                  <div className="header__contacts-city">
                    <p>
                      {data?.header?.data?.attributes.city} <br />{' '}
                      {data?.header?.data?.attributes.street}{' '}
                      {data?.header?.data?.attributes.house}
                    </p>
                  </div>
                  <div className="header__contacts-numbers">
                    <a href={`tel:${data?.header?.data?.attributes.phone_1}`}>
                      {formatPhoneNumber(
                        data?.header?.data?.attributes.phone_1
                      )}
                    </a>
                    <a href={`tel:${data?.header?.data?.attributes.phone_2}`}>
                      {formatPhoneNumber(
                        data?.header?.data?.attributes.phone_2
                      )}
                    </a>
                  </div>
                </div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://vk.com/mik2000"
                  className="header__vk"
                >
                  <VkIcon />
                </a>
                <a
                  href="https://avto-online.pro/kabinet/"
                  target="_blank"
                  rel="noreferrer"
                  className="header__login"
                >
                  <UiButton color="light" label="Личный кабинет" />
                </a>
              </>
            )}

            {!isDesktop && (
              <IconButton>
                <nav className="burger">
                  <div className="burger-menu" onClick={updateMenu}>
                    <div className={burger_class}></div>
                    <div className={burger_class}></div>
                    <div className={burger_class}></div>
                  </div>
                </nav>
              </IconButton>
            )}
          </div>
          {isDesktop && (
            <nav className="header__nav">
              <ul className="header__nav-list">
                {navState?.map(
                  (
                    { label, activeLabel, fixedLabel, to, popup, openPopup },
                    index
                  ) => {
                    if (popup === null) {
                      return (
                        <NavLink
                          className="header__nav-link"
                          key={index}
                          to={to}
                        >
                          <li className="header__nav-item">{label}</li>
                        </NavLink>
                      );
                    } else {
                      return (
                        <div key={index}>
                          <MenuPopupState
                            label={fixedLabel || activeLabel}
                            idxItem={index}
                            IconComponent={ArrowDown}
                            items={popup}
                            onClick={handleClickItem}
                          />
                        </div>
                      );
                    }
                  }
                )}
              </ul>
            </nav>
          )}
        </div>
      </div>

      <div className={menu_class}>
        <div className="mobile-menu__top">
          <a
            href="https://avto-online.pro/kabinet/"
            target="_blank"
            rel="noreferrer"
            className="header__login"
          >
            <UiButton color="light" label="Личный кабинет" />
          </a>
        </div>
        <ul className="mobile-menu__list">
          {navState?.map(
            (
              { label, activeLabel, fixedLabel, to, popup, openPopup },
              index
            ) => {
              if (popup === null) {
                return (
                  <NavLink
                    className="mobile-menu__link"
                    key={index}
                    to={to}
                    onClick={handleCloseMenu}
                  >
                    <li className="mobile-menu__item">{label}</li>
                  </NavLink>
                );
              } else {
                return (
                  <div className="mobile-menu__submenu" key={index}>
                    <Submenu
                      label={fixedLabel || activeLabel}
                      list={popup}
                      onClick={handleCloseMenu}
                    />
                  </div>
                );
              }
            }
          )}
        </ul>

        <div className="mobile-menu__footer">
          <div className="mobile-menu__social">
            <div className="mobile-menu__numbers">
              <a href={`tel:${data?.header?.data?.attributes.phone_1}`}>
                {formatPhoneNumber(data?.header?.data?.attributes.phone_1)}
              </a>
              <a href={`tel:${data?.header?.data?.attributes.phone_2}`}>
                {formatPhoneNumber(data?.header?.data?.attributes.phone_2)}
              </a>
            </div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://vk.com/mik2000"
              className="mobile-menu__vk"
            >
              <VkIcon />
            </a>
          </div>

          <div className="mobile-menu__city">
            <p>
              {data?.header?.data?.attributes.city}{' '}
              {data?.header?.data?.attributes.street}{' '}
              {data?.header?.data?.attributes.house}
            </p>
          </div>
        </div>
      </div>
    </header>
  );
};
