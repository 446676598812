import { useQuery } from '@apollo/client';
import withScrollToTop from 'hocs/withScrollToTop';
import React from 'react';
import { GET_CARS } from 'services/graphql/cars';
import { UiTitle } from 'shared/ui/UiTitle';
//  skeletons
import SkeletonSX from 'assets/images/skeletons/card-sx.png';
const Cars = () => {
  const { loading, error, data } = useQuery(GET_CARS);
  const skeletons = [1, 2, 3, 4, 5, 6];
  return (
    <main className="cars">
      <div className="container">
        <UiTitle className="cars__title" label="Наши автомобили" />
        <div className="cars__content">
          <ul className="cars__list">
            {loading &&
              skeletons.map((value) => (
                <li key={value} className="cars__item">
                  <img
                    src={SkeletonSX}
                    className="skeleton"
                    alt="skeleton-SX"
                  />
                </li>
              ))}
            {!loading &&
              !error &&
              data.cars.data.map(({ id, attributes }, index) => (
                <li key={id} className="cars__item">
                  <div className="cars__item-preview">
                    <img
                      src={
                        process.env.REACT_APP_BACKEND_URL +
                        attributes?.image?.data?.attributes.url
                      }
                      alt={`${attributes.brand - attributes.model}`}
                    />
                  </div>
                  <div className="cars__item-title">
                    <span>
                      {attributes.brand} {attributes.model}
                    </span>
                  </div>
                  <div className="cars__item-details">
                    {attributes.car_detail.map((item) => (
                      <span key={item.id}>
                        {item.year}
                        {item.acp ? ' (АКПП)' : ''}
                      </span>
                    ))}
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </main>
  );
};

export default withScrollToTop(Cars);
