import React from 'react';
import { HomeBooking } from './HomeBooking';
import { HomeServices } from './HomeServices';
import { HomeTeams } from './HomeTeams';
import { HomeSchedule } from './HomeSchedule';
import { HomeCars } from './HomeCars';
import { HomeReviews } from './HomeReviews';
import { HomeQuestions } from './HomeQuestions';
import { Locations } from 'shared/Locations';
import withScrollToTop from 'hocs/withScrollToTop';
import { useQuery } from '@apollo/client';
import { GET_HOME_CONTENT } from 'services/graphql/pages/home';
import { GET_LAST_RECORDS } from 'services/graphql/records';
import { useDevice } from 'hooks/useDevice';

const Home = () => {
  const { loading, error, data } = useQuery(GET_HOME_CONTENT);
  const { isDesktop, isMobile } = useDevice();
  const {
    loading: loadingRecords,
    error: errorRecords,
    data: dataRecords,
  } = useQuery(GET_LAST_RECORDS);
  return (
    <main className="home">
      <HomeBooking
        isLoading={loading}
        image={
          !loading && !error
            ? !isMobile
              ? data?.homepage?.data?.attributes?.home_car_image
              : data?.homepage?.data?.attributes?.home_car_image_mobile
            : null
        }
      />
      <HomeServices
        data={
          !loading && !error ? data?.homepage?.data?.attributes?.services : []
        }
      />

      <HomeSchedule
        items={
          !loadingRecords && !errorRecords ? dataRecords?.records?.data : []
        }
      />

      <HomeTeams
        data={
          !loading && !error
            ? data?.homepage?.data?.attributes?.instructors_top
            : []
        }
        banner={
          !loading && !error ? data?.homepage?.data?.attributes?.banner_1 : null
        }
      />

      <HomeCars
        data={
          !loading && !error ? data?.homepage?.data?.attributes.cars_top : []
        }
        banner={
          !loading && !error ? data?.homepage?.data?.attributes?.banner_2 : null
        }
      />
      <HomeReviews
        data={
          !loading && !error
            ? data?.homepage?.data?.attributes?.reviews_top
            : []
        }
      />
      <HomeQuestions
        data={
          !loading && !error ? data?.homepage?.data?.attributes?.questions : []
        }
      />
      <Locations />
    </main>
  );
};

export default withScrollToTop(Home);
