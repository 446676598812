import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CarSteeringImage from "assets/images/home/car-steering.png";
import Glyph1Icon from "assets/icons/glyph-1.svg";
import Glyph2Icon from "assets/icons/glyph-2.svg";
import { UiButton } from "shared/ui/UiButton";
import { TextField, DialogContentText } from "@mui/material";
import { SmartCaptcha } from "@yandex/smart-captcha";
import { useMutation } from "@apollo/client";
import { CREATE_TRAINING_REQUEST } from "services/graphql/mutations/training-requests";
import { UiModal } from "shared/ui/UiModal";
import { useDevice } from "hooks/useDevice";

//  skeletons
import SkeletonMain from "assets/images/skeletons/home-page/main.png";
import SkeletonMD from "assets/images/skeletons/home-page/md.png";
import SkeletonSX from "assets/images/skeletons/home-page/sx.png";
import SkeletonMainMobile from "assets/images/skeletons/home-page/main-mobile.png";

export const HomeBooking = ({ image, isLoading }) => {
  const { isDesktop, isTablet, isMobile } = useDevice();
  const captchaRef = useRef(null);
  const [createTrainingRequest] = useMutation(CREATE_TRAINING_REQUEST);
  const [token, setToken] = useState("");
  const [status, setStatus] = useState("hidden");
  const [openModal, setOpenModal] = useState(false);
  const [modalStatus, setStatusModal] = useState("success"); // success || error
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "+7",
      loading: false,
      submit: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Введите Имя"),
      phone: Yup.string()
        .matches(/^\+?\d{1,}$/, "Введите правильный номер телефона")
        .min(
          7,
          "Минимальная длина номера телефона должна быть не менее 7 символов"
        )
        .max(
          15,
          "Максимальная длина номера телефона должна быть не более 15 символов"
        )
        .required("Введите номер телефона"),
    }),
    validateOnChange: false,
    onSubmit: async (values, helpers) => {
      helpers.setFieldValue("loading", true);

      if (status !== "success") {
        setStatus("network-error");
        return false;
      }

      try {
        const { data } = await createTrainingRequest({
          variables: {
            name: values.name,
            phone: values.phone,
          },
        });
        helpers.setFieldValue("loading", false);
        resetFormikState();
        setStatusModal("success");
        setOpenModal(true);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
        helpers.setFieldValue("loading", false);
        setOpenModal(true);
        setStatusModal("error");
      }
    },
  });

  const handleChallengeVisible = useCallback(() => setStatus("visible"), []);
  const handleChallengeHidden = useCallback(() => setStatus("hidden"), []);
  const handleNetworkError = useCallback(() => setStatus("network-error"), []);
  const handleSuccess = useCallback((token) => {
    setStatus("success");
    setToken(token);
  }, []);
  const handleTokenExpired = useCallback(() => {
    setStatus("token-expired");
    setToken("");
    captchaRef.current.reset();
  }, []);

  const resetFormikState = () => {
    formik.resetForm();
    formik.setStatus({});
    formik.setErrors({});
    formik.setSubmitting(false);
  };

  useEffect(() => {
    handleChallengeVisible();
  }, [handleNetworkError]);
  const showSkeletons = image?.data?.attributes.url !== null && isLoading;
  return (
    <section className="home-booking">
      <div className="container">
        <div className="home-booking__preview">
          <div className="home-booking__preview-left">
            <img
              className="home-booking__car"
              src={
                showSkeletons
                  ? !isMobile
                    ? SkeletonMain
                    : SkeletonMainMobile
                  : image?.data?.attributes.url
                  ? process.env.REACT_APP_BACKEND_URL +
                    image?.data?.attributes.url
                  : !isMobile
                  ? SkeletonMain
                  : SkeletonMainMobile
              }
              alt=""
            />
            {showSkeletons ? (
              <img
                src={isMobile ? SkeletonSX : SkeletonMD}
                className="skeleton skeleton-md"
                alt="skeleton-MD"
              />
            ) : (
              <div className="home-booking-block">
                <img
                  src={Glyph2Icon}
                  className="home-booking-block__icon"
                  alt="glyph-2"
                />
                <p className="home-booking-block__text">
                  Оплата <br /> в рассрочку
                </p>
              </div>
            )}
          </div>
          {(isTablet || isDesktop) &&
            (showSkeletons ? (
              <>
                <img
                  src={SkeletonSX}
                  className="skeleton"
                  alt="skeleton-SX"
                  name="descount"
                />
                <img
                  src={SkeletonSX}
                  className="skeleton"
                  alt="skeleton-SX"
                  name="image-block"
                />
              </>
            ) : (
              <>
                <div className="home-booking-block" name="descount">
                  <img
                    src={Glyph1Icon}
                    className="home-booking-block__icon"
                    alt="glyph-1"
                  />
                  <p className="home-booking-block__text">
                    Скидки <br /> учащимся <br /> и военным
                  </p>
                </div>
                <div
                  className="home-booking-block home-booking-block--image"
                  name="image-block"
                >
                  <img src={CarSteeringImage} alt="car-steeringImage" />
                </div>
              </>
            ))}

          {!isTablet &&
            !isDesktop &&
            (showSkeletons ? (
              <div className="home-booking__bottom">
                <img
                  src={SkeletonSX}
                  className="skeleton skeleton-sx"
                  alt="skeleton-SX"
                  name="descount"
                />
                <img
                  src={SkeletonSX}
                  className="skeleton skeleton-sx"
                  alt="skeleton-SX"
                  name="image-block"
                />
              </div>
            ) : (
              <div className="home-booking__bottom">
                <div
                  style={{ width: "100%" }}
                  className="home-booking-block"
                  name="descount"
                >
                  <img
                    src={Glyph1Icon}
                    className="home-booking-block__icon"
                    alt="glyph-1"
                  />
                  <p className="home-booking-block__text">
                    Скидки учащимся и военным
                  </p>
                </div>
                <div
                  style={{ width: "100%" }}
                  className="home-booking-block home-booking-block--image"
                  name="image-block"
                >
                  <img src={CarSteeringImage} alt="car-steeringImage" />
                </div>
              </div>
            ))}
        </div>
        {!isMobile && showSkeletons ? (
          <img
            src={SkeletonMD}
            className="skeleton skeleton-form"
            alt="skeleton-MD"
          />
        ) : (
          <form
            noValidate
            onSubmit={formik.handleSubmit}
            className={`home-booking__form ${
              status === "network-error" ? "error" : ""
            }`}
          >
            <h6 className="home-booking__form-title">
              Оставить заявку <br /> на обучение
            </h6>
            <TextField
              error={!!(formik.touched.name && formik.errors.name)}
              fullWidth
              helperText={formik.touched.name && formik.errors.name}
              placeholder="Имя"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.name}
              size="medium"
              className="home-booking__field"
            />
            <TextField
              error={!!(formik.touched.phone && formik.errors.phone)}
              fullWidth
              helperText={formik.touched.phone && formik.errors.phone}
              placeholder="Телефон"
              name="phone"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="tel"
              value={formik.values.phone}
              size="medium"
              className="home-booking__field"
            />
            <div className="home-booking__captcha">
              <SmartCaptcha
                ref={captchaRef}
                sitekey="ysc1_zfkWTI2gHXvamgAbm7G4b8unKmlM7F25d0V3jRZFd5cea92d"
                onChallengeVisible={handleChallengeVisible}
                onChallengeHidden={handleChallengeHidden}
                onNetworkError={handleNetworkError}
                onSuccess={handleSuccess}
                onTokenExpired={handleTokenExpired}
              />
              {status === "network-error" && (
                <div className="captcha-error-text">Пройдите капчу</div>
              )}
            </div>

            <UiButton
              className="home-booking__form-btn"
              label="Записаться"
              color="light"
              type="submit"
              disabled={formik.values.loading}
            />

            <div className="home-booking__form-desc">
              Нажимая «Записаться», Вы соглашаетесь с нашей политикой обработки
              персональных данных
            </div>
          </form>
        )}
      </div>
      {modalStatus === "success" && (
        <UiModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          title="Ваша заявка принята"
        >
          <DialogContentText>
            Скоро наш менеджер свяжется с вами
          </DialogContentText>
        </UiModal>
      )}
      {modalStatus === "error" && (
        <UiModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          title="Что-то пошло не так"
          successed={false}
        >
          <DialogContentText>
            Возможно, сбой соединения. Попробуйте оставить заявку позже или
            просто позвоните нам по номеру: <br />{" "}
            {localStorage.getItem("official_phone") || ""}
          </DialogContentText>
        </UiModal>
      )}
    </section>
  );
};
