import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import React from 'react';
import { UiButton } from './UiButton';
import { ReactComponent as CheckedIcon } from 'assets/icons/checked.svg';
import { ReactComponent as AlertIcon } from 'assets/icons/alert-triangle.svg';
import CloseIcon from '@mui/icons-material/Close';
export const UiModal = ({
  open,
  onClose,
  title,
  children,
  successed = true,
  hideActions = false,
  hideBeforeIcons = false,
}) => {
  return (
    <Dialog className="ui-modal" open={open} onClose={onClose}>
      <DialogTitle>
        {hideBeforeIcons ? null : successed ? <CheckedIcon /> : <AlertIcon />}
        {title}
        <IconButton
          sx={{ position: 'absolute', right: '15px' }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={`${hideBeforeIcons ? '' : 'text-padding'}`}>
        {children}
      </DialogContent>
      {!hideActions && (
        <DialogActions>
          {successed ? (
            <UiButton onClick={onClose} label="Спасибо" color="green" />
          ) : (
            <UiButton onClick={onClose} label="Закрыть" color="red" />
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};
