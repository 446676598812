export const returnCategoryValue = (category) => {
  switch (category) {
    case 'A':
      return 'A';
    case 'B':
      return 'B';
    case 'B_C':
      return 'B_C';
    case 'C_D':
      return 'C_D';

    default:
      break;
  }
};
