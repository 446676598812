import ServicesBanner from "assets/images/home/services-banner.png";
import { Link } from "react-router-dom";
import { UiButton } from "shared/ui/UiButton";
import { ReactComponent as RubleIcon } from "assets/icons/ruble.svg";
import { BannerBadge } from "components/BannerBadge";
import { useDevice } from "hooks/useDevice";
export const HomeServices = ({ data }) => {
  const { isMobile } = useDevice();
  return (
    <section className="home-services">
      <div className="container">
        <header className="home-services__head">
          <h2 className="section__title primary">Наши услуги</h2>
        </header>
        <div className="home-services__content">
          {data?.map(({ id, list, price, category, title, price_banner }) => (
            <div key={id} className="home-services__block">
              <Link to={`/category${category}`}>
                <h3 className="home-services__block-title">{title}</h3>
                {list?.map(({ text }, index) => (
                  <p key={index + 1}>
                    <span>{index + 1}.</span>
                    {text}
                  </p>
                ))}
                <div className="home-services__price">
                  {price_banner && <BannerBadge label={price_banner} />}
                  <UiButton
                    disabled
                    color="green"
                    label={
                      <>
                        <span>{price.toLocaleString("ru-RU")}</span>
                        <RubleIcon />
                      </>
                    }
                  />
                </div>
              </Link>
            </div>
          ))}
          <div className="home-services__banner">
            <img src={ServicesBanner} alt="ServicesBanner" />
          </div>
        </div>
      </div>
    </section>
  );
};
