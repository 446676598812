import React from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { UiButton } from 'shared/ui/UiButton';
export const ScheduleMobile = ({
  data,
  returnCategory,
  returnDayType,
  setOpenModalBooking,
  setModalData,
}) => {
  moment.locale('ru');
  return (
    <section className="schedule-mobile">
      <ul className="schedule-mobile__list">
        {data?.map(
          ({
            id,
            attributes: { category, date, day_type, address },
            index,
          }) => (
            <li className="schedule-mobile__item" key={id}>
              <div className="schedule-mobile__ceil category">
                {returnCategory(category)}
              </div>
              <div className="schedule-mobile__ceil date">
                {moment(date).format('DD MMMM YYYY')}
              </div>
              <div className="schedule-mobile__ceil day_type">
                {returnDayType(day_type)}
              </div>
              <div className="schedule-mobile__ceil address">{address}</div>
              <UiButton
                className="schedule-mobile__btn btn"
                onClick={() => {
                  setOpenModalBooking(true);
                  setModalData({ category, day_type, date, address });
                }}
                label="Записаться"
                color="green"
              />
            </li>
          )
        )}
      </ul>
    </section>
  );
};
