const { gql } = require('@apollo/client');

export const GET_GALLERY_PHOTOS = gql`
  query GetGalleryPhotos($start: Int!, $limit: Int!) {
    galleryPhotos(pagination: { start: $start, limit: $limit }) {
      data {
        id
        attributes {
          image {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          pageSize
          pageCount
          total
        }
      }
    }
  }
`;
