const { gql } = require('@apollo/client');

export const GET_CATEGORY_A_PAGE = gql`
  query GetCategoryAPage {
    categoryAPage {
      data {
        id
        attributes {
          banner {
            title
            btnLabel
            image {
              data {
                attributes {
                  url
                }
              }
            }
            image_mobile {
              data {
                attributes {
                  url
                }
              }
            }
            external_link
            internal_link
            label_link
          }
          content {
            image {
              data {
                id
                attributes {
                  url
                }
              }
            }
            image_mobile {
              data {
                attributes {
                  url
                }
              }
            }

            training_info {
              id
              category
              online
              offline
              price
              transmission
              price_banner
            }

            description

            theoretical_exercises {
              title
              subtitle
              list {
                id
                text
              }
            }

            practical_exercises {
              title
              subtitle
              list {
                id
                text
              }
            }

            organization_exam {
              title
              subtitle
              list {
                id
                text
              }
            }

            theory_time {
              title
              description
            }

            practical_time {
              title
              description
            }
          }
        }
      }
    }
  }
`;
export const GET_CATEGORY_B_PAGE = gql`
  query GetCategoryBPage {
    categoryBPage {
      data {
        id
        attributes {
          banner {
            title
            btnLabel
            image {
              data {
                attributes {
                  url
                }
              }
            }
            image_mobile {
              data {
                attributes {
                  url
                }
              }
            }
            external_link
            internal_link
            label_link
          }
          content {
            image {
              data {
                id
                attributes {
                  url
                }
              }
            }
            image_mobile {
              data {
                attributes {
                  url
                }
              }
            }

            training_info {
              category
              online
              offline
              price
              transmission
              price_banner
            }

            description

            theoretical_exercises {
              title
              subtitle
              list {
                id
                text
              }
            }

            practical_exercises {
              title
              subtitle
              list {
                id
                text
              }
            }

            organization_exam {
              title
              subtitle
              list {
                id
                text
              }
            }

            theory_time {
              title
              description
            }

            practical_time {
              title
              description
            }
          }
        }
      }
    }
  }
`;

export const GET_CATEGORY_BC_PAGE = gql`
  query GetCategoryBCPage {
    categoryBcPage {
      data {
        id
        attributes {
          banner {
            title
            btnLabel
            image {
              data {
                attributes {
                  url
                }
              }
            }
            image_mobile {
              data {
                attributes {
                  url
                }
              }
            }
            external_link
            internal_link
            label_link
          }
          content {
            image {
              data {
                id
                attributes {
                  url
                }
              }
            }
            image_mobile {
              data {
                attributes {
                  url
                }
              }
            }

            training_info {
              category
              online
              offline
              price
              transmission
              price_banner
            }

            description

            theoretical_exercises {
              title
              subtitle
              list {
                id
                text
              }
            }

            practical_exercises {
              title
              subtitle
              list {
                id
                text
              }
            }

            organization_exam {
              title
              subtitle
              list {
                id
                text
              }
            }

            theory_time {
              title
              description
            }

            practical_time {
              title
              description
            }
          }
        }
      }
    }
  }
`;

export const GET_CATEGORY_CD_PAGE = gql`
  query GetCategoryCDPage {
    categoryCdPage {
      data {
        id
        attributes {
          banner {
            title
            btnLabel
            image {
              data {
                attributes {
                  url
                }
              }
            }
            image_mobile {
              data {
                attributes {
                  url
                }
              }
            }
            external_link
            internal_link
            label_link
          }
          content {
            image {
              data {
                id
                attributes {
                  url
                }
              }
            }
            image_mobile {
              data {
                attributes {
                  url
                }
              }
            }

            training_info {
              category
              online
              offline
              price
              transmission
              price_banner
            }

            description

            theoretical_exercises {
              title
              subtitle
              list {
                id
                text
              }
            }

            practical_exercises {
              title
              subtitle
              list {
                id
                text
              }
            }

            organization_exam {
              title
              subtitle
              list {
                id
                text
              }
            }

            theory_time {
              title
              description
            }

            practical_time {
              title
              description
            }
          }
        }
      }
    }
  }
`;
