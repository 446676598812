const { gql } = require('@apollo/client');

export const GET_HOME_CONTENT = gql`
  query GetHomePages {
    homepage {
      data {
        id
        attributes {
          home_car_image {
            data {
              id
              attributes {
                url
              }
            }
          }
          home_car_image_mobile {
            data {
              id
              attributes {
                url
              }
            }
          }

          services {
            id
            category
            title
            list {
              text
            }
            price
            price_banner
          }

          questions {
            id
            title
            description
          }

          cars_top {
            id
            brand
            model
            year
            image {
              data {
                id
                attributes {
                  url
                }
              }
            }
          }

          reviews_top {
            id
            author
            text
            date
          }

          instructors_top {
            id
            full_name
            startInstructorYear
            startTeachYear
            car_name
            transmission
            avatar {
              data {
                id
                attributes {
                  url
                }
              }
            }
          }

          banner_1 {
            title
            btnLabel
            image {
              data {
                attributes {
                  url
                }
              }
            }
            image_mobile {
              data {
                attributes {
                  url
                }
              }
            }
            external_link
            internal_link
            label_link
          }

          banner_2 {
            title
            btnLabel
            image {
              data {
                attributes {
                  url
                }
              }
            }
            image_mobile {
              data {
                attributes {
                  url
                }
              }
            }
            external_link
            internal_link
            label_link
          }
        }
      }
    }
  }
`;
