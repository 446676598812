import { Banner } from 'components/Banner';
import Slider from 'react-slick';
import { ReactComponent as SliderArrow } from 'assets/icons/slider-arrow.svg';
import { UiLink } from 'shared/ui/UiLink';
import { useDevice } from 'hooks/useDevice';
export const HomeTeams = ({ data, banner }) => {
  const { isMobile, isTablet } = useDevice();

  const CustomPrevArrow = ({ onClick }) => (
    <button className="custom-prev-arrow" onClick={onClick}>
      <SliderArrow className="slider-arrow" />
    </button>
  );

  const CustomNextArrow = ({ onClick }) => (
    <button className="custom-next-arrow" onClick={onClick}>
      <SliderArrow className="slider-arrow" />
    </button>
  );

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    appendDots: (dots) => (
      <div>
        <ul className="custom-dots">{dots}</ul>
      </div>
    ),
    customPaging: (i) => <button className="custom-dot"></button>,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          arrows: false,
          dots: false,
          centerMode: true,
          centerPadding: '120px',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 637,
        settings: {
          arrows: false,
          dots: false,
          centerMode: true,
          centerPadding: '35px',
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="home-teams">
      <div className="container">
        {banner !== null && <Banner content={banner} />}
        <h3 className="section-title home-teams__title">
          Учитесь у сильных инструкторов — посмотрите, кто преподает в нашей
          школе
        </h3>
        <div className="home-teams__slider">
          <Slider {...settings}>
            {data?.map(
              ({
                full_name,
                id,
                startInstructorYear,
                car_name,
                avatar,
                transmission,
              }) => (
                <div key={id} className="home-teams__slide">
                  <div className="home-teams__slide-preview">
                    <img
                      src={
                        process.env.REACT_APP_BACKEND_URL +
                        avatar?.data?.attributes.url
                      }
                      alt="Slide 1"
                    />
                  </div>

                  <div className="home-teams__slide-content">
                    <div className="home-teams__slide-info">
                      <h3 className="home-teams__slide-name">{full_name}</h3>
                      {isMobile || isTablet ? (
                        <ul className="home-teams__slide-list mobile">
                          <li>Работает инструктором с {startInstructorYear}</li>
                          <li>
                            {car_name}{' '}
                            {transmission === 'AKPP' ? 'АКПП' : 'МКПП'}
                          </li>
                        </ul>
                      ) : (
                        <ul className="home-teams__slide-list">
                          <li>
                            Учит ездить на{' '}
                            {transmission === 'AKPP' ? 'автомате' : 'механике'}
                          </li>
                          <li>Автомобиль: {car_name}</li>
                          <li>
                            Стаж работы инструктором с {startInstructorYear} г.
                          </li>
                        </ul>
                      )}
                    </div>
                    <div className="home-teams__slide-block">
                      <p>
                        Занимайтесь с тем, кто больше подходит. Если вам не
                        подошел инструктор, его можно сменить в любой момент.
                      </p>
                      <UiLink to="/instructors" label="Наши инструкторы" />
                    </div>
                  </div>
                </div>
              )
            )}
          </Slider>
        </div>
      </div>
    </div>
  );
};
