import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'shared/Layout';

import AboutPage from 'views/About';
import CarsPage from 'views/Cars';
import CategoryAPage from 'views/CategoryA';
import CategoryBPage from 'views/CategoryB';
import CategoryBCPage from 'views/CategoryBC';
import CategoryCDPage from 'views/CategoryCD';
import CollaboratePage from 'views/Collaborate/Collaborate';
import ContactsPage from 'views/Contacts';
import GalleryPage from 'views/Gallery';
import HomePage from 'views/Home';
import InstructorsPage from 'views/Instructors';
import ReviewsPage from 'views/Reviews';
import ShedulePage from 'views/Shedule';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path="categoryA" element={<CategoryAPage />} />
        <Route path="categoryB" element={<CategoryBPage />} />
        <Route path="categoryCD" element={<CategoryCDPage />} />
        <Route path="categoryBC" element={<CategoryBCPage />} />
        <Route path="schedule" element={<ShedulePage />} />
        <Route path="contacts" element={<ContactsPage />} />
        <Route path="reviews" element={<ReviewsPage />} />
        <Route path="instructors" element={<InstructorsPage />} />
        <Route path="about-cars" element={<CarsPage />} />
        <Route path="photo-gallery" element={<GalleryPage />} />
        <Route path="collaboration" element={<CollaboratePage />} />
        <Route path="about-organization" element={<AboutPage />} />
      </Route>
    </Routes>
  );
};

export default App;
