import React from 'react';
import { CategoryHero } from 'shared/CategoryHero';
import { CategoryPage } from 'shared/CategoryPage';
import { Locations } from 'shared/Locations';
import { HomeSchedule } from 'views/Home/HomeSchedule';
import { Banner } from 'components/Banner';
import withScrollToTop from 'hocs/withScrollToTop';
import { useQuery } from '@apollo/client';
import { GET_CATEGORY_B_PAGE } from 'services/graphql/pages/category';
import { GET_FILTERED_RECORDS } from 'services/graphql/records';

const CategoryB = () => {
  const { loading, error, data } = useQuery(GET_CATEGORY_B_PAGE);
  const {
    loading: loadingRecords,
    error: errorRecords,
    data: dataRecords,
  } = useQuery(GET_FILTERED_RECORDS, {
    variables: { activeCategory: 'B' },
  });
  return (
    <main className="category category-b">
      <CategoryHero
        category="B"
        isLoading={loading}
        formTitle={
          <p>
            Запись <br /> на категорию B
          </p>
        }
        blockColor="green"
        image={
          !loading && !error
            ? data?.categoryBPage?.data?.attributes?.content.image
            : null
        }
        image_mobile={
          !loading && !error
            ? data?.categoryBPage?.data?.attributes?.content.image_mobile
            : null
        }
        onAsyncFunc={() => setTimeout(() => alert('Success'), 200)}
      />
      <CategoryPage
        content={
          !loading && !error
            ? data?.categoryBPage?.data?.attributes?.content
            : {}
        }
      />
      <HomeSchedule
        items={
          !loadingRecords && !errorRecords ? dataRecords?.records?.data : []
        }
      />
      {data?.categoryBPage?.data?.attributes?.banner !== null && (
        <div className="container banner__container">
          <Banner content={data?.categoryBPage?.data?.attributes?.banner} />
        </div>
      )}
      <Locations />
    </main>
  );
};

export default withScrollToTop(CategoryB);
