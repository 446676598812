import { ScheduleTable } from 'components/ScheduleTable';
import { UiLink } from 'shared/ui/UiLink';
export const HomeSchedule = ({ items }) => {
  return (
    <section className="home-schedule">
      <div className="container">
        <h2 className="section__title home-schedule__title">
          Ближайшие группы
        </h2>
        <ScheduleTable data={items} />

        <div className="home-schedule__link">
          <UiLink
            to="/schedule"
            label="Посмотреть все расписание"
            color="primary"
          />
        </div>
      </div>
    </section>
  );
};
