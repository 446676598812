import { useQuery } from '@apollo/client';
import Pagination from 'components/Pagination';
import withScrollToTop from 'hocs/withScrollToTop';
import React, { useEffect, useState } from 'react';
import { GET_REVIEWS } from 'services/graphql/reviews';
import { UiTitle } from 'shared/ui/UiTitle';
import moment from 'moment';
import 'moment/locale/ru';
import { useDevice } from 'hooks/useDevice';
import InfiniteScroll from 'react-infinite-scroll-component';
const Reviews = () => {
  const [items, setItems] = useState([]);
  const { isTablet, isMobile } = useDevice();
  moment.locale('ru');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { loading, error, data } = useQuery(GET_REVIEWS, {
    variables: { start: page, limit: rowsPerPage },
    onCompleted: (data) => {
      setTotalCount(data.reviews.meta.pagination.pageCount);
      setItems((prevState) => [...prevState, ...data?.reviews?.data]);
    },
  });

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
    const pageNumber = value - 1;
    if (pageNumber >= 0) {
      setPage(pageNumber * rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  console.log(items);
  const more = () => {
    handleChangePage(null, currentPage + 1);
  };
  const skeletons = [1, 2, 3, 4];
  return (
    <main className="reviews">
      <div className="container">
        <UiTitle className="reviews__title" label="Отзывы наших выпускников" />

        {isTablet || isMobile ? (
          <>
            <InfiniteScroll
              dataLength={items?.length}
              next={more}
              hasMore={true}
              className="infinity-scroll"
              // loader={<p>Loading...</p>}
              // endMessage={<p>No more data to load.</p>}
            >
              <ul className="infinity-scroll__list">
                {items?.map(({ id, attributes }) => (
                  <li key={id} className="reviews__item">
                    <div className="reviews__item-info">
                      <div className="reviews__item-date">
                        {moment(attributes?.date).format('DD MMMM YYYY')}
                      </div>
                      <div className="reviews__item-author">
                        {attributes.author}
                      </div>
                    </div>
                    <p className="reviews__item-text">{attributes.text}</p>
                  </li>
                ))}
              </ul>
            </InfiniteScroll>
          </>
        ) : (
          <div className="reviews__content">
            <ul className="reviews__list">
              {loading &&
                skeletons.map((value) => (
                  <li key={value} className="reviews__item skeleton"></li>
                ))}
              {!loading &&
                !error &&
                data.reviews.data.map(({ id, attributes }) => (
                  <li key={id} className="reviews__item">
                    <div className="reviews__item-info">
                      <div className="reviews__item-date">
                        {moment(attributes?.date).format('DD MMMM YYYY')}
                      </div>
                      <div className="reviews__item-author">
                        {attributes.author}
                      </div>
                    </div>
                    <p className="reviews__item-text">{attributes.text}</p>
                  </li>
                ))}

              <Pagination
                count={totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                onChange={handleChangePage}
                onChangePageSize={handleChangeRowsPerPage}
                pageSize={rowsPerPage}
              />
            </ul>
          </div>
        )}
      </div>
    </main>
  );
};

export default withScrollToTop(Reviews);
