const { gql } = require('@apollo/client');

export const GET_RECORDS = gql`
  query GetRecords {
    records(pagination: { page: 1, pageSize: 100 }) {
      data {
        id
        attributes {
          category
          date
          day_type
          address
        }
      }
      meta {
        pagination {
          page
          pageSize
          pageCount
          total
        }
      }
    }
  }
`;

export const GET_LAST_RECORDS = gql`
  query GetLastRecords {
    records(sort: "createdAt:desc", pagination: { start: 0, limit: 3 }) {
      data {
        id
        attributes {
          category
          date
          day_type
          address
          createdAt
        }
      }
    }
  }
`;

export const GET_FILTERED_RECORDS = gql`
  query GetFilteredRecords($activeCategory: String!) {
    records(filters: { category: { eq: $activeCategory } }) {
      data {
        id
        attributes {
          category
          date
          day_type
          address
        }
      }
    }
  }
`;
