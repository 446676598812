import React from "react";
import MuiPagination from "@mui/material/Pagination";
import {
  ThemeProvider,
  createTheme,
  IconButton,
  Stack,
  MenuItem,
  Select,
} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
const Pagination = ({
  count,
  currentPage,
  setCurrentPage,
  onChange,
  pageSize,
  onChangePageSize,
}) => {
  const handleClickLastArrow = () => {
    onChange(null, count);
    setCurrentPage(count);
  };
  const handleClickFirstArrow = () => {
    onChange(null, 1);
    setCurrentPage(1);
  };

  return (
    <div className="pagination">
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <ThemeProvider
          theme={createTheme({
            components: {
              MuiPaginationItem: {
                defaultProps: {
                  // @ts-expect-error Material-UI issue
                  components: {
                    previous: WestIcon,
                    next: EastIcon,
                  },
                },
              },
            },
          })}
        >
          <Stack direction="row" spacing="4px" alignItems="center">
            <IconButton onClick={handleClickFirstArrow}>
              <FirstPageIcon />
            </IconButton>
            <MuiPagination
              count={count}
              page={currentPage}
              onChange={onChange}
            />
            <IconButton onClick={handleClickLastArrow}>
              <LastPageIcon />
            </IconButton>
          </Stack>
        </ThemeProvider>

        <div className="sorting">
          <label>Показывать по:</label>
          <ThemeProvider
            theme={createTheme({
              palette: {
                primary: {
                  main: "#37DFC1",
                },
              },
            })}
          >
            <Select
              variant="outlined"
              onChange={onChangePageSize}
              value={pageSize}
            >
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
            </Select>
          </ThemeProvider>
        </div>
      </Stack>
    </div>
  );
};

export default Pagination;
