import React from 'react';

export const UiButton = ({
  label,
  onClick,
  type,
  color = 'primary',
  size = 'medium',
  className = '',
  disabled = false,
}) => {
  const classNames = `ui-button ui-button--${size} ui-button--${color} ${className} ${
    disabled ? 'ui-button--disabled' : ''
  }`;
  return (
    <button type={type} className={classNames} onClick={onClick}>
      <p>{label}</p>
    </button>
  );
};
