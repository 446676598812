const { gql } = require('@apollo/client');

export const GET_REVIEWS = gql`
  query GetReviews($start: Int!, $limit: Int!) {
    reviews(pagination: { start: $start, limit: $limit }) {
      data {
        id
        attributes {
          author
          text
          date
        }
      }
      meta {
        pagination {
          page
          pageSize
          pageCount
          total
        }
      }
    }
  }
`;
