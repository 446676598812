const {
  InMemoryCache,
  ApolloClient,
  ApolloProvider,
} = require('@apollo/client');

const StrapiApolloProvider = ({ children }) => {
  const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_URL,
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default StrapiApolloProvider;
