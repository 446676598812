import { gql } from '@apollo/client';

export const CREATE_COLLABORATION = gql`
  mutation createCollaboration(
    $name: String!
    $phone: String!
    $text: String!
  ) {
    createCollaboration(data: { name: $name, phone: $phone, text: $text }) {
      data {
        id
        attributes {
          name
          phone
          text
        }
      }
    }
  }
`;
