import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Description } from '@mui/icons-material';

const AccordionQuestions = ({ items }) => {
  return (
    <div className="accordion-questions">
      {items.map(({ id, title, description }, index) => (
        <Accordion
          key={id}
          sx={{ marginBottom: '8px', boxShadow: 'none', border: '0px' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#472B82' }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h5 className="accordion-questions__title">{title}</h5>
          </AccordionSummary>
          <AccordionDetails>
            <p className="accordion-questions__desc">{description}</p>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default AccordionQuestions;
