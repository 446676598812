import { useDevice } from "hooks/useDevice";
import { UiTitle } from "./ui/UiTitle";
import { CategoryPageMobile } from "./CategoryPageMobile";
import { BannerBadge } from "components/BannerBadge";

export const CategoryPage = ({ content }) => {
  const { isMobile } = useDevice();
  const returnTransmission = (value) => {
    switch (value) {
      case "MKPP":
        return "механика";
      case "AKPP":
        return "автомат";
      default:
        break;
    }
  };
  const returnCategory = (category) => {
    switch (category) {
      case "A":
        return "Категория A";
      case "B":
        return "Категория B";
      case "B_C":
        return "Переобучение с категории B на C";
      case "C_D":
        return "Переобучение с категории C на D";

      default:
        break;
    }
  };

  const returnStatusTraining = (online, offline) => {
    if (online && offline) return "Онлайн/очное обучение";
    else {
      if (online) return "Онлайн обучение";
      else if (offline) return "Очное обучение";
      else return "";
    }
  };

  if (isMobile)
    return (
      <CategoryPageMobile
        content={content}
        returnStatusTraining={returnStatusTraining}
        returnTransmission={returnTransmission}
        returnCategory={returnCategory}
      />
    );
  return (
    <div className="category-page">
      <div className="container">
        <UiTitle
          className="category-page__title"
          label="Стоимость и процесс обучения"
        />
      </div>

      <div className="container category-page__container">
        {content?.training_info?.map((item) => (
          <div
            key={item?.id}
            className="category-page__price"
            style={{ maxWidth: item?.price_banner ? "860px" : "750px" }}
          >
            <span>
              {returnCategory(item?.category)}{" "}
              {item?.transmission
                ? "(" + returnTransmission(item?.transmission) + ")"
                : ""}
            </span>
            <span>{returnStatusTraining(item?.online, item?.offline)}</span>
            <span>{item?.price.toLocaleString("ru-RU")} рублей</span>

            {item?.price_banner && <BannerBadge label={item.price_banner} />}
          </div>
        ))}

        <p className="category-page__text">{content?.description}</p>
        <div className="category-page__content">
          <div className="category-page__content-left">
            <h5 className="category-page__subtitle">Как проходит обучение</h5>

            <div className="category-page__info">
              <h6 className="category-page__info-title">
                {content?.theoretical_exercises?.title}
              </h6>
              {content?.theoretical_exercises?.subtitle && (
                <p className="category-page__info-subtitle">
                  {content?.theoretical_exercises?.subtitle}
                </p>
              )}
              <ul className="category-page__list">
                {content?.theoretical_exercises?.list?.map((item, index) => (
                  <li key={item.id} className="category-page__item">
                    {item.text}
                  </li>
                ))}
              </ul>
            </div>

            <div className="category-page__info">
              <h6 className="category-page__info-title">
                {content?.practical_exercises?.title}
              </h6>
              {content?.practical_exercises?.subtitle && (
                <p className="category-page__info-subtitle">
                  {content?.practical_exercises?.subtitle}
                </p>
              )}
              <ul className="category-page__list">
                {content?.practical_exercises?.list?.map((item, index) => (
                  <li key={item.id} className="category-page__item">
                    {item.text}
                  </li>
                ))}
              </ul>
            </div>

            <div className="category-page__info">
              <h6 className="category-page__info-title">
                {content?.organization_exam?.title}
              </h6>
              {content?.organization_exam?.subtitle && (
                <p className="category-page__info-subtitle">
                  {content?.organization_exam?.subtitle}
                </p>
              )}
              <ul className="category-page__list">
                {content?.organization_exam?.list?.map((item, index) => (
                  <li key={item.id} className="category-page__item">
                    {item.text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="category-page__content-right">
            <div className="category-page__time">
              <h4 className="category-page__time-title">
                {content?.theory_time?.title}
              </h4>
              <p className="category-page__time-text">
                {content?.theory_time?.description}
              </p>
            </div>
            <div className="category-page__time">
              <h4 className="category-page__time-title">
                {content?.practical_time?.title}
              </h4>
              <p className="category-page__time-text">
                {content?.practical_time?.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
