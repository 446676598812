import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as ArrowBold } from 'assets/icons/arrow-bold.svg';
export const UiLink = ({ to, label, color = 'primary', center = false }) => {
  return (
    <NavLink
      to={to}
      className={`ui-link ${color} ${center ? 'ui-link--centered' : ''}`}
    >
      <span className="ui-link__label">{label}</span>
      <ArrowBold className="ui-link__arrow" />
    </NavLink>
  );
};
