import AccordionQuestions from 'components/AccordionQuestions';
import React from 'react';
import { UiTitle } from 'shared/ui/UiTitle';

export const HomeQuestions = ({ data }) => {
  const items = [
    {
      title: 'Есть возможность занятий по индивидуальному графику?',
      desc: 'Repellat rem, nihil a labore perspiciatis animi porro explicabo sit temporibus repellendus debitis mollitia, aperiam quasi quo consequuntur ex architecto deserunt? Temporibus, illum! Ab earum ullam veritatis expedita recusandae deserunt.',
    },
    {
      title: 'Можно взять дополнительные занятия, если мне не хватило?',
      desc: 'Да, конечно. Позвоните нашему менеджеру по номеру +7 (903) 124 12 61 и вам расскажут как это сделать.',
    },
    {
      title: 'Можно скидку, если прохожу к вас обучение на еще одну категорию?',
      desc: 'Repellat rem, nihil a labore perspiciatis animi porro explicabo sit temporibus repellendus debitis mollitia, aperiam quasi quo consequuntur ex architecto deserunt? Temporibus, illum! Ab earum ullam veritatis expedita recusandae deserunt.',
    },
    {
      title: 'Сколько длится обучение?',
      desc: 'Repellat rem, nihil a labore perspiciatis animi porro explicabo sit temporibus repellendus debitis mollitia, aperiam quasi quo consequuntur ex architecto deserunt? Temporibus, illum! Ab earum ullam veritatis expedita recusandae deserunt.',
    },
  ];
  return (
    <div className="home-questions">
      <div className="container">
        <UiTitle label="Ответы на частые вопросы" />
        <AccordionQuestions items={data} />
      </div>
    </div>
  );
};
